'use client'

import {
  Flex,
  Stack
} from '@chakra-ui/react'
import BrandLogo from './Logo'
import DarkMode from './DarkMode'
import { useSearchParams, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types';


const AnonHeader = ({ route }) => {
  const prevLocation = useLocation()
  const [searchParams] = useSearchParams()
  let queryParams = ""
  let redirectTo = ''
  if (route !== '/login') {
    for (const entry of searchParams.entries())  {
      const [param, value] = entry
      queryParams += `${param}=${value}&`
    }
    redirectTo = prevLocation.pathname + (queryParams ? `?${queryParams}` : '')
    console.log('route', redirectTo)
  }
  
  return (
    <Flex h={16} alignItems={'center'} justifyContent={'space-between'} p={4}>
    {route ? (
      <>
        <BrandLogo />
        <Flex alignItems={'center'}>
          <Stack direction={'row'} spacing={7}>
            <DarkMode/>
          </Stack>
        </Flex>
      </>
    ) : (
      <>
        <BrandLogo />
        <Flex alignItems={'center'}>
          <Stack direction={'row'} spacing={7}>
            <DarkMode/>
            {/* <WebLink to='/login' redirectTo={redirectTo}>
              <Button variant="brandPrimary" size="lg">
                Sign In
              </Button>
            </WebLink> */}
          </Stack>
        </Flex>
      </>
    )}
    </Flex>
  )
}

AnonHeader.propTypes = {
  route: PropTypes.string.isRequired,
};

export default AnonHeader